import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AthleteModalDataModel } from '../models/athlete-modal-data.model';
import { FollowModalDataModel } from '../models/follow-modal-data.model';
import { RaceVideoModalDataModel } from '../models/race-video-modal-data.model';
import { TrackTeamResultModalDataModel } from '../models/track-team-result-modal-data.model';
import { XcTeamSummaryModalDataModel } from '../models/xc-team-summary-data.model';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _updateAthleteModalData: BehaviorSubject<AthleteModalDataModel | null> =
    new BehaviorSubject<AthleteModalDataModel | null>(null);
  public readonly updateAthleteModalData: Observable<AthleteModalDataModel | null> =
    this._updateAthleteModalData.asObservable();

  private _updateTrackTeamResultModalData: BehaviorSubject<TrackTeamResultModalDataModel | null> =
    new BehaviorSubject<TrackTeamResultModalDataModel | null>(null);
  public readonly updateTrackTeamResultModalData: Observable<TrackTeamResultModalDataModel | null> =
    this._updateTrackTeamResultModalData.asObservable();

  private _updateRaceVideoModalData: BehaviorSubject<RaceVideoModalDataModel | null> =
    new BehaviorSubject<RaceVideoModalDataModel | null>(null);
  public readonly updateRaceVideoModalData: Observable<RaceVideoModalDataModel | null> =
    this._updateRaceVideoModalData.asObservable();

  private _updateFollowModalData: BehaviorSubject<FollowModalDataModel | null> =
    new BehaviorSubject<FollowModalDataModel | null>(null);
  public readonly updateFollowModalData: Observable<FollowModalDataModel | null> =
    this._updateFollowModalData.asObservable();

  private _updateXcTeamSummaryModalData: BehaviorSubject<XcTeamSummaryModalDataModel | null> =
    new BehaviorSubject<XcTeamSummaryModalDataModel | null>(null);
  public readonly updateXcTeamSummaryModalData: Observable<XcTeamSummaryModalDataModel | null> =
    this._updateXcTeamSummaryModalData.asObservable();

  constructor(private router: Router) {}

  setAthleteModalData(athleteModalData: AthleteModalDataModel | null) {
    this._updateAthleteModalData.next(athleteModalData);
  }

  async openAthleteModal(model: AthleteModalDataModel, route: ActivatedRoute, openOnPageLoad: boolean = false) {
    //console.log('openAthleteModal', openOnPageLoad ? 'openOnPageLoad' : 'DO NOT openOnPageLoad');
    model.openOnPageLoad = openOnPageLoad;
    this.setAthleteModalData(model);
    if (model.athleteId > 0) {
      await this.router.navigate(['athlete', model.athleteId], { relativeTo: route });
    }
  }

  setTrackTeamResultModalData(modalData: TrackTeamResultModalDataModel | null) {
    this._updateTrackTeamResultModalData.next(modalData);
  }

  async openTrackTeamResultModal(
    model: TrackTeamResultModalDataModel,
    route: ActivatedRoute,
    openOnPageLoad: boolean = false,
  ) {
    model.openOnPageLoad = openOnPageLoad;
    this.setTrackTeamResultModalData(model);
    if (model.resultId > 0) {
      await this.router.navigate(['track-team-result', model.resultId], { relativeTo: route });
    }
  }

  setRaceVideoModalData(raceVideoModalData: RaceVideoModalDataModel | null) {
    this._updateRaceVideoModalData.next(raceVideoModalData);
  }

  async openRaceVideoModal(model: RaceVideoModalDataModel, route: ActivatedRoute, openOnPageLoad: boolean = false) {
    model.openOnPageLoad = openOnPageLoad;
    this.setRaceVideoModalData(model);
    if (model.videoId) {
      await this.router.navigate(['race-video', model.videoId], { relativeTo: route });
    }
  }

  setFollowModalData(followModalData: FollowModalDataModel | null) {
    this._updateFollowModalData.next(followModalData);
  }

  async openFollowModal(model: FollowModalDataModel, route: ActivatedRoute, openOnPageLoad: boolean = false) {
    model.openOnPageLoad = openOnPageLoad;
    this.setFollowModalData(model);
    if (model.dataContext.dataSourceType && model.dataContext.athleticLiveId > 0) {
      await this.router.navigate(
        ['follow', model.dataContext.dataSourceType + '-' + model.dataContext.athleticLiveId],
        {
          relativeTo: route,
        },
      );
    }
  }

  setXcTeamSummaryModalData(xcTeamSummaryModalData: XcTeamSummaryModalDataModel | null) {
    this._updateXcTeamSummaryModalData.next(xcTeamSummaryModalData);
  }

  async openXcTeamSummaryModalData(
    model: XcTeamSummaryModalDataModel,
    route: ActivatedRoute,
    openOnPageLoad: boolean = false,
  ) {
    model.openOnPageLoad = openOnPageLoad;
    this.setXcTeamSummaryModalData(model);
    // Using "_" as a separator for the liveTeamId and liveRoundId isntead of "-" because the "-" is used in the liveRoundId
    await this.router.navigate(
      ['xc-team-summary', model.teamId ? model.teamId : model.liveTeamId + '_' + model.liveRoundId],
      {
        relativeTo: route,
      },
    );
  }

  public isModalUrl(url: string): boolean {
    const modalUrlRegexes = [
      /athlete\/\d+$/gm,
      /xc-team-summary\/\w+$/gm,
      /follow\/\d+$/gm,
      /track-team-result\/\d+$/gm,
      /race-video\/\w+$/gm,
    ];
    let navigatingToModal = false;
    modalUrlRegexes.forEach((regex) => {
      if (url.match(regex)) {
        navigatingToModal = true;
      }
    });
    return navigatingToModal;
  }
}

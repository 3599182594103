import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { EventSummary, LiveFieldEvent, MeetList, TeamList } from '@athleticnet/athletic-live-api-models';
import { LiveRunEvent } from '@athleticnet/athletic-live-api-models/lib/models/firebase/live-run-event.model';
import { MeetEventSummary } from '@athleticnet/athletic-live-api-models/lib/models/firebase/meet-event-summary.interface';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subscription, catchError, combineLatest, map, of } from 'rxjs';
import { CurrentEventModel } from '../models/current-event.model';
import { CurrentTimezoneModel } from '../models/current-timezone.model';
import { EventFilterModel } from '../models/event-filter.model';
import { LocalMeetStorageInterface } from '../models/local-meet-storage.interface';
import { LocalStorageService } from './local-storage.service';
import { SiteConfigService } from './site-config.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentMeetService {
  meet: MeetList | null;
  events: MeetEventSummary | null;
  liveRunEvents: LiveRunEvent[] | null;
  liveFieldEvents: LiveFieldEvent[] | null;
  loadedEvents: boolean = false;
  eventFilterModel: EventFilterModel | null = null;
  filteredEvents: EventSummary[] | null = null; // Null is used to indicate that the events are not loaded
  firstLevelFilter: string | null = null;
  firstLevelFilterName: string | null = null;
  secondLevelFilter: string | null = null;
  secondLevelFilterChoices?: string[] = [];
  currentSection: string | null = null;
  meetSearchTerm: string | null = null;
  followTab: string | null = null;
  filteredEventsSub: Subscription;
  searchedEventsSub: Subscription;
  meetStorage!: LocalMeetStorageInterface | null;

  teams: TeamList[] | null;
  filteredTeams: TeamList[] = [];

  currentEvent: CurrentEventModel | null = null;
  currentTimezone: CurrentTimezoneModel | null = null;

  private _updateMeet: BehaviorSubject<MeetList | null> = new BehaviorSubject<MeetList | null>(null);
  public readonly updateMeet: Observable<MeetList | null> = this._updateMeet.asObservable();

  private _updateEvents: BehaviorSubject<MeetEventSummary | null> = new BehaviorSubject<MeetEventSummary | null>(null);
  public readonly updateEvents: Observable<MeetEventSummary | null> = this._updateEvents.asObservable();

  private _updateLiveRunEvents: BehaviorSubject<LiveRunEvent[] | null> = new BehaviorSubject<LiveRunEvent[] | null>(
    null,
  );
  public readonly updateLiveRunEvents: Observable<LiveRunEvent[] | null> = this._updateLiveRunEvents.asObservable();

  private _updateLiveFieldEvents: BehaviorSubject<LiveFieldEvent[] | null> = new BehaviorSubject<
    LiveFieldEvent[] | null
  >(null);
  public readonly updateLiveFieldEvents: Observable<LiveFieldEvent[] | null> =
    this._updateLiveFieldEvents.asObservable();

  private _updateEventFilterModel: BehaviorSubject<EventFilterModel | null> =
    new BehaviorSubject<EventFilterModel | null>(null);
  public readonly updateEventFilterModel: Observable<EventFilterModel | null> =
    this._updateEventFilterModel.asObservable();

  private _updateFilteredEvents: BehaviorSubject<EventSummary[] | null> = new BehaviorSubject<EventSummary[] | null>(
    null,
  );
  public readonly updateFilteredEvents: Observable<EventSummary[] | null> = this._updateFilteredEvents.asObservable();

  private _updateCurrentEvent: BehaviorSubject<CurrentEventModel | null> =
    new BehaviorSubject<CurrentEventModel | null>(null);
  public readonly updateCurrentEvent: Observable<CurrentEventModel | null> = this._updateCurrentEvent.asObservable();

  private _updateCurrentSection: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public readonly updateCurrentSection: Observable<string | null> = this._updateCurrentSection.asObservable();

  private _updateMeetSearchTerm: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public readonly updateMeetSearchTerm: Observable<string | null> = this._updateMeetSearchTerm.asObservable();

  private _updateFollowTab: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public readonly updateFollowTab: Observable<string | null> = this._updateFollowTab.asObservable();

  private _updateSearchedEvents: BehaviorSubject<EventSummary[]> = new BehaviorSubject<EventSummary[]>([]);
  public readonly updateSearchedEvents: Observable<EventSummary[]> = this._updateSearchedEvents.asObservable();

  private _updateCurrentTimezone: BehaviorSubject<CurrentTimezoneModel | null> =
    new BehaviorSubject<CurrentTimezoneModel | null>(null);
  public readonly updateCurrentTimezone: Observable<CurrentTimezoneModel | null> =
    this._updateCurrentTimezone.asObservable();

  constructor(
    private siteConfigService: SiteConfigService,
    private localStorageService: LocalStorageService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.meet = null;
    this.events = null;
    this.liveRunEvents = null;
    this.liveFieldEvents = null;
    this.teams = null;

    this.filteredEventsSub = this.setFilteredEvents(this.updateEvents, this.updateEventFilterModel).subscribe(
      (filteredEvents) => {
        this.filteredEvents = filteredEvents;
        this._updateFilteredEvents.next(filteredEvents);
      },
    );

    this.searchedEventsSub = this.setSearchedEvents(this.updateEvents, this.updateMeetSearchTerm).subscribe(
      (searchedEvents) => {
        this._updateSearchedEvents.next(searchedEvents);
      },
    );
  }

  setCurrentSection(section: string) {
    this.currentSection = section;
    this._updateCurrentSection.next(section);
  }

  setCurrentEvent(eventCategory: string, eventId: number) {
    const standardizedEventCategory =
      eventCategory.toLowerCase().charAt(0).toUpperCase() + eventCategory.slice(1).toLowerCase();
    if (this.meet) {
      this.currentEvent = new CurrentEventModel(this.meet.id, standardizedEventCategory, eventId);

      // If this event id doesn't exist in the current filter, select an appropriate filter
      if (this.filteredEvents && this.filteredEvents.length > 0) {
        const event = this.filteredEvents.find((event) => {
          return event.id === eventId;
        });

        if (!event) {
          if (this.events) {
            const eventKey = standardizedEventCategory + '-' + eventId;
            const event = this.events[eventKey];
            if (event) {
              if (event.session) {
                this.changeFirstLevelFilter('sessions', event.session);
              } else if (event.summaryGroup) {
                this.changeFirstLevelFilter('summaryGroups', event.summaryGroup);
              } else if (event.abbrev) {
                this.changeFirstLevelFilter('type', event.abbrev);
              } else if (event.combinedEventName) {
                this.changeFirstLevelFilter('combined', event.combinedEventName);
              }
            }
          }
        }
      }

      this._updateCurrentEvent.next(this.currentEvent);
    } else {
      this._updateCurrentEvent.next(null);
    }
  }

  unsetCurrentEvent() {
    this.currentEvent = null;
    this._updateCurrentEvent.next(this.currentEvent);
  }

  setEventFilterModal(eventFilterModel: EventFilterModel) {
    this.eventFilterModel = eventFilterModel;
    this._updateEventFilterModel.next(eventFilterModel);
    if (this.meet && this.meetStorage) {
      this.meetStorage.eventFilter.firstLevelFilter = eventFilterModel.firstLevelFilter;
      this.meetStorage.eventFilter.firstLevelFilterName = eventFilterModel.firstLevelFilterName;
      this.meetStorage.eventFilter.secondLevelFilter = eventFilterModel.secondLevelFilter;
      this.localStorageService.setLocalMeetStorage(this.meet.id, this.meetStorage);
    }
  }

  async unsetMeet() {
    this.meet = null;
    this.events = null;
    this.liveRunEvents = null;
    this.liveFieldEvents = null;
    this.eventFilterModel = null;
    this.filteredEvents = [];
    this.firstLevelFilter = null;
    this.firstLevelFilterName = null;
    this.secondLevelFilter = null;
    this.secondLevelFilterChoices = [];
    this._updateMeet.next(null);
    this._updateEvents.next(null);
    this._updateLiveRunEvents.next(null);
    this._updateLiveFieldEvents.next(null);
    this._updateEventFilterModel.next(null);
    this._updateFilteredEvents.next([]);
    await this.siteConfigService.setConfigByOrigin();
  }

  async setMeet(meet: MeetList) {
    let changedMeet = false;
    if (!this.meet || this.meet.id !== meet.id) {
      changedMeet = true;
    }
    this.meet = meet;
    // // localize sessions, summaryGroups, eventAbbrevs, combinedEventName
    // if (this.locale !== 'en_US' && meet.localize && this.locale in meet.localize) {
    //   if (meet.sessions && meet.localize[this.locale].sessions) {
    //     meet.sessions = meet.sessions.map((session) => {
    //       return meet.localize[this.locale].sessions[session] || session;
    //     });
    //   }
    //   if (meet.summaryGroups && meet.localize[this.locale].summaryGroups) {
    //     meet.summaryGroups = meet.summaryGroups.map((summaryGroup) => {
    //       return meet.localize[this.locale].summaryGroups[summaryGroup] || summaryGroup;
    //     });
    //   }
    //   if (meet.eventAbbrevs && meet.localize[this.locale].eventAbbrevs) {
    //     meet.eventAbbrevs = meet.eventAbbrevs.map((eventAbbrev) => {
    //       return meet.localize[this.locale].eventAbbrevs[eventAbbrev] || eventAbbrev;
    //     });
    //   }
    //   if (meet.combinedEventName && meet.localize[this.locale].combinedEventNames) {
    //     meet.combinedEventName = meet.combinedEventName.map((combinedEventName) => {
    //       return meet.localize[this.locale].combinedEventNames[combinedEventName] || combinedEventName;
    //     });
    //   }
    // }
    if (changedMeet) {
      this.meetStorage = this.localStorageService.getLocalMeetStorage(this.meet.id);
      if (
        (this.meetStorage.eventFilter.firstLevelFilter === null &&
          this.meetStorage.eventFilter.secondLevelFilter === null) ||
        (this.meetStorage && DateTime.fromISO(this.meetStorage.expires).toMillis() < DateTime.now().toMillis())
      ) {
        const now = DateTime.now();
        // If multiple sessions, default to the first session on this day
        if (meet.sessions && meet.sessionDays) {
          for (let i = 0, len = meet.sessions.length; i < len; i++) {
            if (meet.sessionDays[i] === now.toFormat('yyyy-MM-dd')) {
              this.meetStorage.eventFilter.firstLevelFilter = 'sessions';
              this.meetStorage.eventFilter.secondLevelFilter = meet.sessions[i];
              break;
            }
          }
        }
      }
      this.changeFirstLevelFilter(
        this.meetStorage?.eventFilter.firstLevelFilter ?? '',
        this.meetStorage?.eventFilter.secondLevelFilter ?? null,
      );
      await this.siteConfigService.setConfig(meet.site);
      if (meet.siteSkin) {
        await this.siteConfigService.setSkinConfig(meet.siteSkin);
      }

      const currentTime = DateTime.local();
      const meetTime = DateTime.local({ zone: this.meet.timezone });
      this.currentTimezone = new CurrentTimezoneModel(
        this.meet.timezone,
        meetTime.toFormat('ZZZZ'),
        meetTime.toFormat('ZZ'),
        currentTime.toFormat('z'),
        currentTime.toFormat('ZZZZ'),
        currentTime.toFormat('ZZ'),
        'local',
      );
      this._updateCurrentTimezone.next(this.currentTimezone);
    }
    this._updateMeet.next(meet);
  }

  setCurrentTimezone(activeTimezoneLabel: string) {
    if (this.currentTimezone) {
      this.currentTimezone.setActiveTimezone(activeTimezoneLabel);
      this._updateCurrentTimezone.next(this.currentTimezone);
    }
  }

  setEvents(events: MeetEventSummary) {
    this.events = events;
    this.loadedEvents = true;
    this._updateEvents.next(events);
  }

  setLiveRunEvents(liveRunEvents: LiveRunEvent[]) {
    this.liveRunEvents = liveRunEvents;
    this._updateLiveRunEvents.next(liveRunEvents);
  }

  setLiveFieldEvents(liveFieldEvents: LiveFieldEvent[]) {
    this.liveFieldEvents = liveFieldEvents;
    this._updateLiveFieldEvents.next(liveFieldEvents);
  }

  changeFirstLevelFilter(
    firstLevelFilter: string = '',
    secondLevelFilter: string | null = null,
    autoSelectSecondLevelFilter: boolean = true,
  ) {
    if (this.meet && this.meet.sport !== 'xc') {
      // Attempt to discover if the selected item no longer exists and try to find the next best choice.
      if (
        (firstLevelFilter === 'sessions' && (!this.meet?.sessions || this.meet?.sessions.length === 0)) ||
        (firstLevelFilter === 'summaryGroups' &&
          (!this.meet?.summaryGroups || this.meet?.summaryGroups.length === 0)) ||
        (firstLevelFilter === 'eventAbbrevs' && (!this.meet?.eventAbbrevs || this.meet?.eventAbbrevs.length === 0)) ||
        (firstLevelFilter === 'combinedEventName' &&
          (!this.meet?.combinedEventName || this.meet?.combinedEventName.length === 0))
      ) {
        firstLevelFilter = '';
        secondLevelFilter = null;
      }

      if (!firstLevelFilter) {
        if (this.meet?.sessions && this.meet?.sessions.length > 0) {
          firstLevelFilter = 'sessions';
        } else if (this.meet?.summaryGroups && this.meet?.summaryGroups.length > 0) {
          firstLevelFilter = 'summaryGroups';
        } else if (this.meet?.eventAbbrevs && this.meet?.eventAbbrevs.length > 0) {
          firstLevelFilter = 'type';
        } else if (this.meet?.combinedEventName && this.meet?.combinedEventName.length > 0) {
          firstLevelFilter = 'combined';
        }
      }

      let defaultSecondLevelFilter: string | null = null;
      try {
        switch (firstLevelFilter) {
          case 'sessions':
            this.secondLevelFilterChoices = this.meet?.sessions;
            this.firstLevelFilterName = $localize`:Header above a list of items in a schedule@@currentMeetSchedule:Schedule`;
            defaultSecondLevelFilter = this.meet.sessions[0];
            break;
          case 'summaryGroups':
            this.secondLevelFilterChoices = this.meet?.summaryGroups;
            if (this.meet?.divisions || this.meet?.ageGroups) {
              this.firstLevelFilterName = $localize`:Header above a list of events of a division (e.g. Varsity, Invitational)@@currentMeetSummaryGroupDivision:Division`;
            } else {
              this.firstLevelFilterName = $localize`:Header above a list of events of a particular gender (e.g. Boys, Men, Girls)@@currentMeetSummaryGroupGender:Gender`;
            }
            defaultSecondLevelFilter = this.meet.summaryGroups[0];
            break;
          case 'type':
            this.secondLevelFilterChoices = this.meet?.eventAbbrevs;
            this.firstLevelFilterName = $localize`:Header above a list of events of a particular type (e.g. 100m, Shot Put)@@currentMeetType:Type`;
            defaultSecondLevelFilter = this.meet.eventAbbrevs[0];
            break;
          case 'combined':
            this.secondLevelFilterChoices = this.meet?.combinedEventName;
            this.firstLevelFilterName = $localize`:Header above a list of multi events@@currentMeetMulti:Multi`;
            defaultSecondLevelFilter = this.meet.combinedEventName[0];
            break;
        }
      } catch (e) {
        // If a meet is reset and the selected filter is no longer available, reset the filter
        firstLevelFilter = '';
        secondLevelFilter = null;
        defaultSecondLevelFilter = null;
      }

      this.secondLevelFilter = secondLevelFilter;
      if (secondLevelFilter === null && autoSelectSecondLevelFilter) {
        this.secondLevelFilter = defaultSecondLevelFilter;
      }

      this.firstLevelFilter = firstLevelFilter;
      const eventFilterModel = new EventFilterModel(
        firstLevelFilter,
        this.secondLevelFilter,
        this.firstLevelFilterName,
      );
      this.setEventFilterModal(eventFilterModel);
    }
  }

  changeSecondLevelFilter(secondLevelFilter: string) {
    if (this.firstLevelFilter && secondLevelFilter && this.meet?.sport != 'xc') {
      const eventFilterModel = new EventFilterModel(
        this.firstLevelFilter,
        secondLevelFilter,
        this.firstLevelFilterName,
      );
      this.setEventFilterModal(eventFilterModel);
    }
  }

  setFilteredEvents(
    events$: Observable<MeetEventSummary | null>,
    filter$: Observable<EventFilterModel | null>,
  ): Observable<EventSummary[] | null> {
    return combineLatest([events$, filter$]).pipe(
      map(([events, filter]: [MeetEventSummary | null, EventFilterModel | null]) => {
        if (!this.loadedEvents) {
          // If events are not loaded return null
          return null;
        }
        let filteredEvents: EventSummary[] = [];
        if (!events) {
          return filteredEvents;
        }
        if (!filter || filter.isEmpty()) {
          Object.keys(events).forEach((key) => {
            if (!events![key].cancelled) {
              filteredEvents.push(events![key]);
            }
          });
        }
        //
        // if (filter) {
        //   let secondLevelValue = filter.secondLevelFilter;
        //
        //   if (this.locale !== 'en_US' && this.meet?.localize && this.locale in this.meet?.localize) {
        //     if (this.meet?.sessions && this.meet?.localize[this.locale].sessions) {
        //       Object.keys(this.meet?.localize[this.locale].sessions).forEach((englishName) => {
        //         if (this.meet?.localize[this.locale].sessions[englishName] === secondLevelValue) {
        //           secondLevelValue = englishName;
        //         }
        //       });
        //     }
        //     // if (meet.summaryGroups && meet.localize[this.locale].summaryGroups) {
        //     //   meet.summaryGroups = meet.summaryGroups.map((summaryGroup) => {
        //     //     return meet.localize[this.locale].summaryGroups[summaryGroup] || summaryGroup;
        //     //   });
        //     // }
        //     // if (meet.eventAbbrevs && meet.localize[this.locale].eventAbbrevs) {
        //     //   meet.eventAbbrevs = meet.eventAbbrevs.map((eventAbbrev) => {
        //     //     return meet.localize[this.locale].eventAbbrevs[eventAbbrev] || eventAbbrev;
        //     //   });
        //     // }
        //     // if (meet.combinedEventName && meet.localize[this.locale].combinedEventNames) {
        //     //   meet.combinedEventName = meet.combinedEventName.map((combinedEventName) => {
        //     //     return meet.localize[this.locale].combinedEventNames[combinedEventName] || combinedEventName;
        //     //   });
        //     // }
        //   }
        // }

        Object.keys(events).forEach((key) => {
          if (!events![key].cancelled) {
            if (filter && filter.firstLevelFilter === 'sessions' && events![key].session === filter.secondLevelFilter) {
              filteredEvents.push(events![key]);
            }
            if (
              filter &&
              filter.firstLevelFilter === 'summaryGroups' &&
              events![key].summaryGroup === filter.secondLevelFilter
            ) {
              filteredEvents.push(events![key]);
            }
            if (filter && filter.firstLevelFilter === 'type' && events![key].abbrev === filter.secondLevelFilter) {
              filteredEvents.push(events![key]);
            }
            if (
              filter &&
              filter.firstLevelFilter === 'combined' &&
              events![key].combinedEventName === filter.secondLevelFilter
            ) {
              filteredEvents.push(events![key]);
            }
          }
        });

        if (filteredEvents && filteredEvents.length > 0) {
          return filteredEvents.sort((a: EventSummary, b: EventSummary): number => {
            if (a && b) {
              if (filter && filter.firstLevelFilter === 'sessions' && this.meet?.sport !== 'xc') {
                return a.sessionSortOrder < b.sessionSortOrder ? -1 : 1;
              } else {
                if (this.meet?.sport === 'xc') {
                  // Sort by date, then session, then sortKey, then name

                  if (a.date && b.date && a.date !== b.date) {
                    return a.date < b.date ? -1 : 1;
                  }

                  if (a.sessionSortOrder && b.sessionSortOrder && a.sessionSortOrder !== b.sessionSortOrder) {
                    return a.sessionSortOrder < b.sessionSortOrder ? -1 : 1;
                  }

                  if (a.sortKey !== b.sortKey) {
                    return a.sortKey < b.sortKey ? -1 : 1;
                  }

                  return a.name.localeCompare(b.name, undefined, { numeric: true });
                }

                if (a.sortKey === b.sortKey) {
                  if (a.summaryGroup === b.summaryGroup) {
                    if (a.roundSortKey === b.roundSortKey) {
                      return a.name.localeCompare(b.name, undefined, { numeric: true });
                    } else {
                      return a.roundSortKey < b.roundSortKey ? -1 : 1;
                    }
                  } else {
                    return a.summaryGroup.localeCompare(b.summaryGroup, undefined, { numeric: true });
                  }
                } else {
                  return a.sortKey < b.sortKey ? -1 : 1;
                }
              }
            } else if (!a) {
              return 1;
            } else if (!b) {
              return -1;
            } else {
              return 0;
            }
          });
        } else {
          return filteredEvents;
        }
      }),
      catchError((error) => {
        return of([]); //return from(['A','B','C'])
      }),
    );
  }

  setCurrentMeetSearchTerm(searchTerm: string) {
    this._updateMeetSearchTerm.next(searchTerm);
  }

  setFollowTab(followTab: string) {
    this._updateFollowTab.next(followTab);
  }

  setSearchedEvents(
    events$: Observable<MeetEventSummary | null>,
    searchTerm$: Observable<string | null>,
  ): Observable<EventSummary[]> {
    return combineLatest([events$, searchTerm$]).pipe(
      map(([events, searchTerm]: [MeetEventSummary | null, string | null]) => {
        let searchedEvents: EventSummary[] = [];
        if (!events) {
          return searchedEvents;
        }
        if (!searchTerm) {
          return searchedEvents;
        }
        let singleTerms = searchTerm.toLowerCase().split(' ');
        if (singleTerms.length > 0) {
          if (singleTerms.length > 3) {
            singleTerms = singleTerms.slice(0, 3);
          }
          Object.keys(events).forEach((key) => {
            let matches = 0;
            singleTerms.forEach((item) => {
              if (
                events![key].name.toLowerCase().includes(item) ||
                events![key].shortName.toLowerCase().includes(item) ||
                events![key].summaryName.toLowerCase().includes(item)
              ) {
                matches++;
              }
            });
            if (matches === singleTerms.length) {
              searchedEvents.push(events![key]);
            }
          });
        }
        return searchedEvents.sort((a, b) => {
          if (a.sortKey === b.sortKey) {
            if (a.summaryGroup === b.summaryGroup) {
              if (a.roundSortKey === b.roundSortKey) {
                return a.name.localeCompare(b.name, undefined, { numeric: true });
              } else {
                return a.roundSortKey < b.roundSortKey ? -1 : 1;
              }
            } else {
              return a.summaryGroup.localeCompare(b.summaryGroup, undefined, { numeric: true });
            }
          } else {
            return a.sortKey < b.sortKey ? -1 : 1;
          }
        });
      }),
      catchError((error) => {
        return of([]); //return from(['A','B','C'])
      }),
    );
  }

  ngOnDestroy() {
    if (this.filteredEventsSub) {
      this.filteredEventsSub.unsubscribe();
    }
    if (this.searchedEventsSub) {
      this.searchedEventsSub.unsubscribe();
    }
  }
}
